import { UseCase } from '@oz/shared/out/enums/UseCase';

import { AppRoute } from '../../enums/AppRoute';

export const getUseCaseByLocation = (pathname: string): UseCase => {
    if (pathname.includes(AppRoute.ABMELDUNG)) {
        return UseCase.OA;
    }
    return UseCase.OZ;
};
