import { Dispatch } from 'redux';

import { OzDatenModellSegment } from '@oz/shared/out/enums/zulassung/OzDatenModellSegment';
import ortnamenLookup from 'store/sideEffects/zulassung/effects/ortnamenLookup';
import strassennamenLookup from 'store/sideEffects/zulassung/effects/strassennamenLookup';
import zulassungstellenLookup from 'store/sideEffects/zulassung/effects/zulassungstellenLookup';

// eslint-disable-next-line
export default (dispatch: Dispatch): void => {
    ortnamenLookup([OzDatenModellSegment.HALTER, OzDatenModellSegment.ADRESSE], dispatch);
    ortnamenLookup([OzDatenModellSegment.HALTER, OzDatenModellSegment.ABWEICHENDE_LIEFERADRESSE], dispatch);
    strassennamenLookup([OzDatenModellSegment.HALTER, OzDatenModellSegment.ADRESSE], dispatch);
    strassennamenLookup([OzDatenModellSegment.HALTER, OzDatenModellSegment.ABWEICHENDE_LIEFERADRESSE], dispatch);
    zulassungstellenLookup(dispatch);
};

// usage:
/*
registerDataModelListener<[ListenerCallbackItem<Situation>, ListenerCallbackItem<Halter>]>(
    [
        [DatenModellSegment.BERECHNEN, DatenModellSegment.SITUATION],
        [DatenModellSegment.BERECHNEN, DatenModellSegment.HALTER],
    ],
    (values, { initial, state }) => {
        console.log({ initial, berechnen: state.datenModell.berechnen });
        const [situation, halter] = values;
        // const val = situation.value;
        console.log('callback triggered', initial);
        console.log(situation.value);
        console.log(halter.value);

        console.log(situation.changed);
        console.log(halter.changed);
    }
);
*/
