import { OA_EMPTY_REENTRY_LINK, OZ_EMPTY_REENTRY_LINK } from '@oz/shared/out/constants/webapp';
import { EntryType } from '@oz/shared/out/enums/EntryType';

import { AppRoute } from '../../enums/AppRoute';

export const isOaEntry = (entryType: EntryType): boolean => entryType.startsWith('OA_');

export const getReEntryLink = (locationPathname: string): string => {
    if (locationPathname.includes(AppRoute.ABMELDUNG)) {
        return OA_EMPTY_REENTRY_LINK;
    }
    return OZ_EMPTY_REENTRY_LINK;
};
