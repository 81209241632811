import { Location } from 'react-router-dom';

import { Tracking } from '@oz/shared/out/interfaces/Tracking';
import { getEntry } from 'modules/applicationConfig';

import { OzRoute } from '../../../enums/zulassung/OzRoute';
import { getCurrentSection, getTrackingConfig } from '../util';

import { BASE_URL } from './constants';
import fillTrackingContainer from './fillTrackingContainer';

export function dispatchPageTracking(trackingModell: Tracking, location: Location): void {
    try {
        const sectionName = getCurrentSection(location);
        const currentRoute = location.pathname as OzRoute;
        const { pageTrackingName } = getTrackingConfig(sectionName, currentRoute, getEntry()?.type);

        // creates the global _paq object, see https://developer.matomo.org/guides/tracking-javascript-guide
        fillTrackingContainer(trackingModell, pageTrackingName);

        const d = document;
        const g = d.createElement('script');
        const s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.src = `${BASE_URL}matomo.js`;
        // @ts-ignore
        s.parentNode.insertBefore(g, s);
    } catch (e) {
        // oh well
    }
}
