import { EntryType } from '@oz/shared/out/enums/EntryType';
import { isOneOf } from '@oz/shared/out/modules/enum';
import { getEntry } from 'modules/applicationConfig';
import { isOaEntry } from 'modules/routing/entry';

import { OaRoute } from '../enums/abmeldung/OaRoute';
import { OzRoute } from '../enums/zulassung/OzRoute';

const ozInitialRoute = (entryType: EntryType): OzRoute => {
    if (isOneOf(entryType, [EntryType.OZ_ID, EntryType.OZ_BACKOFFICE])) {
        return OzRoute.HUB;
    }

    if (isOneOf(entryType, [EntryType.OZ_CANCEL_ANTRAG])) {
        return OzRoute.BEREITS_ZUGELASSEN;
    }

    return OzRoute.DATENEINGABE;
};

const oaInitialRoute = (): OaRoute => {
    return OaRoute.DATENEINGABE;
};

export default (): OzRoute | OaRoute => {
    const entryType = getEntry()?.type;
    return isOaEntry(entryType) ? oaInitialRoute() : ozInitialRoute(entryType);
};
