import createRouteWithSession from 'modules/routing/createRouteWithSession';

import { OaRoute } from '../enums/abmeldung/OaRoute';
import { OzRoute } from '../enums/zulassung/OzRoute';

import useField from './useField';
import useSessionId from './useSessionId';

export default (route: OaRoute | OzRoute) => {
    const sessionId = useSessionId();
    const field = useField();

    return createRouteWithSession(route, sessionId, field);
};
