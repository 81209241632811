import { Location } from 'react-router-dom';

import { OzRoute } from '../../../enums/zulassung/OzRoute';
import { getDevice, getTrackingData } from '../../applicationConfig';
import { getCurrentSection, getTrackingConfig } from '../util';

const GT_SITE_ID = 192;
const GT_BASE_URL = `//asset42.check24.de/gif/site_id/${GT_SITE_ID}/`;
const GT_PRODUCT_NAME = 'kfz-oz';

const dispatchPixel = url => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.bottom = '0';
    const pixel = document.createElement('img');
    pixel.height = 1;
    pixel.width = 1;
    pixel.border = '0';

    pixel.src = url;

    container.appendChild(pixel);
    document.getElementsByTagName('body')[0].appendChild(container);
};

export const dispatchGeneralTracking = (location: Location): void => {
    const trackingData = getTrackingData();

    if (trackingData) {
        const { id: trackingId, cpref, cpid } = trackingData;
        if (cpid && trackingId) {
            const deviceOutput = getDevice();
            const cprefFragment = cpref ? `/cpref/${cpref}` : '';
            const currentRoute = location.pathname as OzRoute;
            const sectionName = getCurrentSection(location);

            const { gtActionId, pageTrackingName } = getTrackingConfig(sectionName, currentRoute, trackingData.entryType);

            // page tracking
            if (pageTrackingName) {
                dispatchPixel(
                    `${GT_BASE_URL}cpid/${cpid}${cprefFragment}/area_id/${pageTrackingName}/product/${GT_PRODUCT_NAME}/sid/${trackingId}/deviceoutput/${deviceOutput}/image.gif`
                );
            }

            // action tracking
            if (gtActionId) {
                dispatchPixel(
                    `${GT_BASE_URL}cpid/${cpid}${cprefFragment}/area_id/${pageTrackingName}/action_id/${gtActionId}/product/${GT_PRODUCT_NAME}/sid/${trackingId}/deviceoutput/${deviceOutput}/image.gif`
                );
            }
        }
    }
};
