import { DeviceType } from '@kfz/check-ui/context/ThemeContext';
import { DeviceOutput } from '@kfz/web-toolkit/client';

import { EntryType } from '@oz/shared/out/enums/EntryType';
import { Endpoint } from '@oz/shared/out/interfaces/Endpoint';
import { SessionInfoModell } from '@oz/shared/out/interfaces/SessionInfoModell';
import { Tracking } from '@oz/shared/out/interfaces/Tracking';
import { BankVorschlag } from '@oz/shared/out/interfaces/zulassung/BankVorschlag';
import { UiInfoModell } from '@oz/shared/out/interfaces/zulassung/UiInfoModell';
import { isOneOf } from '@oz/shared/out/modules/enum';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';

interface ApplicationConfig {
    sessionInfo: SessionInfoModell;
    tracking: Tracking;
    apiKeys: Endpoint[];
    uiInfo: UiInfoModell;
    bankVorschlaege: BankVorschlag[];
    prefilledHalterFields: ObjectSearchPath[];
    startSignLink?: string;
}

let applicationConfig: ApplicationConfig;

export const initializeApplicationConfig = (config: ApplicationConfig) => {
    applicationConfig = config;
    // set tracking data for greaseApp
    try {
        // @ts-ignore
        window.KFZDEBUG = { ...applicationConfig.tracking };
    } catch (e) {
        // oh well ...
    }
};

// @ts-ignore
export const getDevice = () => window.KfzZula.device as DeviceOutput;

export const getDeviceType = (): DeviceType => {
    switch (getDevice()) {
        case DeviceOutput.DESKTOP:
        case DeviceOutput.TABLET:
        case DeviceOutput.TABLETAPP:
            return DeviceType.DESKTOP;
        case DeviceOutput.MOBILE:
        case DeviceOutput.APP:
            return DeviceType.MOBILE;
        default:
            return DeviceType.DESKTOP;
    }
};

export const getSessionId = () => applicationConfig?.sessionInfo.sessionId;

export const getApiKeys = () => applicationConfig?.apiKeys;

export const isMobile = () => isOneOf(getDevice(), [DeviceOutput.MOBILE, DeviceOutput.APP]);
export const isApp = () => isOneOf(getDevice(), [DeviceOutput.TABLETAPP, DeviceOutput.APP]);
export const isDesktop = () => getDevice() === DeviceOutput.DESKTOP;

export const getEntry = () => applicationConfig?.sessionInfo.entry;

export const isBoEntry = () => getEntry()?.type === EntryType.OZ_BACKOFFICE;

export const getHersteller = () => applicationConfig.uiInfo.hersteller;

export const getBankVorschlaege = () => applicationConfig.bankVorschlaege;

export const isEvbEditable = () => applicationConfig?.sessionInfo.isEvbEditable;

export const getTrackingData = () => applicationConfig.tracking;

export const isMatomoEnabled = () => applicationConfig.tracking.isMatomoEnabled;

export const getMatomoTrackingId = () => applicationConfig.tracking.matomoSiteId;

export const getPrefilledHalterFields = () => applicationConfig.prefilledHalterFields;

export const setStartSignLink = (link: string) => {
    applicationConfig.startSignLink = link;
};

export const getStartSignLink = () => applicationConfig.startSignLink;

export const getVertragLabel = () => applicationConfig.uiInfo.kfzVersicherungsvertragLabel;
