// Slice
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OaMetaData } from '@oz/shared/out/interfaces/abmeldung/OaMetaData';
import { DatenModell } from '@oz/shared/out/interfaces/DatenModell';
import { OzMetaData } from '@oz/shared/out/interfaces/zulassung/OzMetaData';
import { setIn } from '@oz/shared/out/modules/object/object';
import { isOaDatenModell, isOzDatenModell } from '@oz/shared/out/types/guards';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';

type NullableDatenModell = DatenModell | null;

const slice = createSlice({
    name: 'datenModell',
    initialState: null as NullableDatenModell,
    reducers: create => ({
        setDatenModell: create.reducer(
            (state: NullableDatenModell, action: PayloadAction<DatenModell>): NullableDatenModell => {
                return action.payload;
            }
        ),
        setValue: create.reducer(
            <T>(
                state: NullableDatenModell,
                action: PayloadAction<{ path: ObjectSearchPath; value: T }>
            ): NullableDatenModell => {
                const setPath = [...action.payload.path];

                return setIn(state, setPath, action.payload.value) as NullableDatenModell;
            }
        ),
        setValues: create.reducer(
            <T>(state: NullableDatenModell, action: PayloadAction<{ path: ObjectSearchPath; value: T }[]>): void => {
                action.payload.forEach(payload => {
                    const setPath = [...payload.path];
                    setIn(state, setPath, payload.value);
                });
            }
        ),
        addMetaDataToDatenModell: create.reducer(
            (state: NullableDatenModell, action: PayloadAction<Partial<OaMetaData> | Partial<OzMetaData>>): void => {
                if (state != null) {
                    if (isOaDatenModell(state)) {
                        // eslint-disable-next-line no-param-reassign
                        state.metaData = { ...state.metaData, ...action.payload } as OaMetaData;
                    } else if (isOzDatenModell(state)) {
                        // eslint-disable-next-line no-param-reassign
                        state.metaData = { ...state.metaData, ...action.payload };
                    }
                }
            }
        ),
    }),
});
export default slice.reducer;

// Actions
export const { addMetaDataToDatenModell, setDatenModell, setValue } = slice.actions;
