import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Section } from '@oz/shared/out/types/types';

import { SectionsState } from '../../interfaces/SectionsState';

// Slice
const slice = createSlice({
    name: 'sections',
    initialState: { sequentialMode: true, sections: [], showSubmitSection: false },
    reducers: create => ({
        initSections: create.reducer((state: SectionsState, action: PayloadAction<Section[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.sequentialMode = true;
            // eslint-disable-next-line no-param-reassign
            state.sections = action.payload.map((section, index) => {
                return { section, editable: index === 0 };
            });
        }),
        setSequentialMode: create.reducer((state: SectionsState, action: PayloadAction<boolean>) => {
            // eslint-disable-next-line no-param-reassign
            state.sequentialMode = action.payload;
        }),
        setAllSectionsCompact: create.reducer((state: SectionsState) => {
            // eslint-disable-next-line no-param-reassign
            state.sections = state.sections.map(section => {
                return { section: section.section, editable: false };
            });
        }),
        setEditable: create.reducer(
            (state: SectionsState, action: PayloadAction<{ section: Section; editable: boolean }>) => {
                const { payload } = action;
                if (state.sequentialMode) {
                    // eslint-disable-next-line no-param-reassign
                    state.sections = state.sections.map(section => {
                        const isTarget = section.section === payload.section;
                        const editable = isTarget ? payload.editable : !payload.editable;
                        return { section: section.section, editable };
                    });
                    return;
                }

                // eslint-disable-next-line no-param-reassign
                const section = state.sections.at(state.sections.findIndex(s => s.section === payload.section));
                if (section) {
                    section.editable = payload.editable;
                }
            }
        ),
        setShowSubmitSection: create.reducer((state: SectionsState, action: PayloadAction<boolean>) => {
            // eslint-disable-next-line no-param-reassign
            state.showSubmitSection = action.payload;
        }),
    }),
});
export default slice.reducer;

// Actions
export const { initSections, setSequentialMode, setEditable, setAllSectionsCompact, setShowSubmitSection } = slice.actions;
