import cloneDeep from 'lodash/cloneDeep';

import { ApplicationState } from '../../interfaces/ApplicationState';
import { getSessionId } from '../applicationConfig';
import { sessionStorageHelper } from '../storage';

const STORAGE_KEY = 'kfzZulaSession';

interface StoreData {
    id: string;
    data: ApplicationState;
}

export const saveInSessionStorage = (store: ApplicationState): void => {
    // Zero time out in order to a) not block the calling thread b) not break the calling thread in case of an error
    setTimeout(() => {
        const clone = cloneDeep(store);
        const sessionId = getSessionId();
        const saveObj = {
            id: sessionId,
            data: clone,
        } as StoreData;
        sessionStorageHelper.setItem(STORAGE_KEY, JSON.stringify(saveObj));
    }, 0);
};

export const getStateFromSessionStorage = (sessionId: string): ApplicationState | undefined => {
    const sessionJsonString = sessionStorageHelper.getItem(STORAGE_KEY);

    if (sessionJsonString) {
        try {
            const session = JSON.parse(sessionJsonString) as StoreData;
            // only restore a session if its id matches the current session id
            if (session.id === sessionId) {
                return session.data;
            }
        } catch (e) {
            // TODO: log error unable to restore session from storage: e
            return undefined;
        }
    }

    return undefined;
};

export const clearSessionStorageData = (): void => {
    sessionStorageHelper.removeItem(STORAGE_KEY);
};
