import debounce from 'lodash/debounce';

import { getFromDatenModellAtPath } from '@oz/shared/out/selectors/zulassung/ozDatenModell';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';
import { saveInSessionStorage } from 'modules/storageSync/sessionStorage';

import { ApplicationState } from '../../interfaces/ApplicationState';

type StateListener = (oldState: ApplicationState, newState: ApplicationState) => void;
interface SideEffectInfo {
    initial: boolean;
    state: ApplicationState;
}

const dataModelListeners: StateListener[] = [];
let initial = true;
let prevState: ApplicationState;
let getState: () => ApplicationState;

const onStateChange = () => {
    if (!getState) {
        throw new Error('No stateGetter defined. Please call initialize function first');
    }
    const newState = getState();
    saveInSessionStorage(newState);
    if (newState?.datenModell !== prevState?.datenModell) {
        dataModelListeners.forEach(listenerFunction => {
            listenerFunction(prevState, newState);
        });
    }
    initial = false;
    prevState = newState;
};

export const onStoreChange = debounce(onStateChange, 5, { trailing: true });

export const initialize = (statGetterFunction: () => ApplicationState): void => {
    getState = statGetterFunction;
};

export const getCurrentState = (): ApplicationState => {
    if (!getState) {
        throw new Error('Cannot get state, store was not initialized');
    }
    return getState();
};

export const registerDataModelListener = <T>(
    fields: ObjectSearchPath[],
    listener: (vals: T, initial: SideEffectInfo) => void
): void => {
    dataModelListeners.push((oldState, newState) => {
        const ret = fields.map(field => {
            const oldVal = getFromDatenModellAtPath(field)(oldState);
            const newVal = getFromDatenModellAtPath(field)(newState);
            return {
                value: newVal,
                changed: oldVal !== newVal,
            };
        });
        if (ret.some(item => item.changed)) {
            if (!getState) {
                throw new Error('No stateGetter defined. Please call initialize function first');
            }
            listener(ret as unknown as T, { initial, state: newState });
        }
    });
    // initial trigger at register time
    const args = fields.map(field => ({
        value: getFromDatenModellAtPath(field)(getState()),
        changed: true,
    }));
    listener(args as unknown as T, { initial: true, state: getState() });
    initial = false;
    prevState = getState();
};
